<template>
    <div id="sample-info">
        <div class="sample-info-title">样本基本信息</div>
        <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget.</div>

        <div class="sample-info-title">基因表达量</div>
        <a-input-search
            placeholder="请输入基因id"
            style="width: 10rem;position: absolute;right: 0;"
            size="small"
            @search="tableSearch"
        />
        <a-table
            :columns="columns"
            :data-source="data"
            rowKey="gene_id"
            size="small" :scroll="{x:true}">
        </a-table>
    </div>
</template>

<script>
const columns = [
    {
        title: "基因ID",
        dataIndex: 'gene_id',
        key: 'gene_id'
    },
    {
        title: '基因symbol',
        dataIndex: 'gene_symbol',
        key: 'gene_symbol',
    },
    {
        title: '表达量',
        dataIndex: 'expression_quantity',
        key: 'expression_quantity',
    }
];

const data = [
    {
        gene_id: 'id1',
        gene_symbol: 's1',
        expression_quantity: "23"
    },
    {
        gene_id: 'id2',
        gene_symbol: 's2',
        expression_quantity: "17"
    },
    {
        gene_id: 'id3',
        gene_symbol: 's3',
        expression_quantity: "66"
    },
    {
        gene_id: 'id4',
        gene_symbol: 's4',
        expression_quantity: "25"
    }
];
export default {
    name: "LayoutDataSetSampleInfo",
    data() {
        return {
            data,
            columns,
        };
    },
    methods: {
        tableSearch(){}
    }
}
</script>

<style scoped lang="scss">
#sample-info {
    width: 70vw;
    margin-left: calc(15vw - 50px);
    position: relative;

    .sample-info-title{
        font-size: 1.2rem;
        font-weight: bold;
        margin-top: 1rem;
    }

  ::v-deep .ant-table {
        margin-top: 30px;
    }

    .action-icon {
        .anticon {
            font-size: 1rem;
            cursor: pointer;
        }
    }
}
</style>