import { render, staticRenderFns } from "./SampleInfo.vue?vue&type=template&id=07c6fbc2&scoped=true&"
import script from "./SampleInfo.vue?vue&type=script&lang=js&"
export * from "./SampleInfo.vue?vue&type=script&lang=js&"
import style0 from "./SampleInfo.vue?vue&type=style&index=0&id=07c6fbc2&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07c6fbc2",
  null
  
)

export default component.exports